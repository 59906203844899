<template>
  <section>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="card-title ">
          Student Ledger
        </p>
      </div>

      <!-- select & search input -->
      <div class="custom-search d-flex">
        <!-- Search for Student ID  -->
        <b-form-group
          class="pr-0 col-lg-3 col-md-3"
          invalid-feedback="Student Id is required"
          :state="idState"
        >
          <vue-autosuggest
            v-model="studentIDStr"
            :suggestions="searchStudents"
            :limit="10"
            :input-props="{
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Search Student ID',
            }"
            :get-suggestion-value="getSuggestionValueForStudent"
            type="number"
            @selected="onSelectedStudent"
            @input="onInputChangeStudent"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">
                {{ suggestion.item.full_name }} ({{
                  suggestion.item.student_id
                }})<br>
                <span class="custom-font-size">
                  Class : {{ suggestion.item.std_academic.student_class.name }}
                  <span
                    v-if="suggestion.item.std_academic.student_section"
                  >({{
                    suggestion.item.std_academic.student_section.name
                  }})</span>
                  <span v-else>(N/A)</span>, Roll :
                  <span v-if="suggestion.item.std_academic.roll_no">{{
                    suggestion.item.std_academic.roll_no
                  }}</span>
                  <span v-else>(N/A)</span>,
                  {{ suggestion.item.std_academic.branch.name }}
                  ({{ suggestion.item.std_academic.secondary_session.name }})
                </span>
              </span>
            </template>
          </vue-autosuggest>
        </b-form-group>

        <b-form-group
          class="pr-0 col-lg-2 col-md-2"
        >
          <flat-pickr
            v-model="fromDateTime"
            placeholder="From Date/Time"
            class="form-control"
            required
          />
          <!-- :config="{ enableTime: true,dateFormat: 'Y-m-d H:i',defaultDate: 'today'}" -->
        </b-form-group>

        <b-form-group
          class="pr-0 col-lg-2 col-md-2"
        >
          <flat-pickr
            v-model="toDateTime"
            placeholder="To Date/Time"
            class="form-control"
            required
          />
        </b-form-group>

        <b-form-group
          label-sr-only
          class="pr-0 col-lg-4 col-md-4"
        >
          <b-button
            type="submit"
            variant="outline-success"
            class="mr-1"
            @click.prevent="searchFunc"
          >
            Search
          </b-button>
          <b-button
            type="submit"
            variant="outline-danger"
            class="mr-1"
            @click.prevent="clearFunc"
          >
            Clear
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
            class="mr-1"
            @click.prevent="printFunc"
          >
            Print
          </b-button>
        </b-form-group>
      </div>

      <div v-if="is_show">
        <div class="m-2">
          <b>
            Student Name: {{ studentData.name }} | Student ID:
            {{ studentData.id }} | Campus: {{ studentData.campusName }} | Class:
            {{ studentData.className }} | Session: {{ studentData.sessionName }}
          </b>
        </div>
      </div>
      <div style="text-align:center">
        <b-spinner
          v-if="isLoading"
          variant="primary"
          label="Text Centered"
        />
      </div>

      <div v-if="isShow">
        <table
          class="table table-hover table-bordered"
        >
          <thead>
            <tr class="left-align">
              <th
                width="5px"
                class="left-align"
              >
                SL.
              </th>
              <th
                width="200px"
                class="left-align"
              >
                Date
              </th>
              <th
                width="500px"
                class="left-align"
              >
                Description
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Debit
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Credit
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Balance
              </th>
            </tr>
          </thead>
          <tbody v-if="isShow && rows.length > 0">
            <tr
              v-for="(item, index) in rows"
              :key="item.id"
              class="left-align"
            >
              <td class="left-align">
                {{ rows.length === index + 1 ? '' : index + 1 }}
              </td>
              <td class="left-align">
                {{ item.date }}
              </td>
              <td class="left-align">
                {{ rows.length === index + 1 ? 'Total' : item.description }}
              </td>
              <td class="right-align">
                <b>{{ item.debit }}</b>
              </td>
              <td class="right-align">
                <b>{{ item.credit }}</b>
              </td>
              <td class="right-align">
                <b>{{ item.balance }}</b>
                <!-- <h1 style="text-decoration: underline double;"><u>About Us</u></h1> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </b-card>
    <LedgerPdf
      v-if="isPrintDocument"
      ref="docRef"
      :document-data="documentData"
      :student-info="studentInfo"
      :date-range="dateRange"
    />
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  VBModal,
  BSpinner,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import { showOnlyDateDBFormat } from '@/@service/utils/utils'
import LedgerPdf from '@/views/student-ledger/components/LedgerPdf.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BButton,
    VueAutosuggest,
    flatPickr,
    BSpinner,
    LedgerPdf,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {

      pageLength: 10,
      dir: false,
      rows: [],
      tempRows: [],

      /*
      * propertiess for student search
      */
      studentID: '',
      searchStudents: [],
      studentName: '',
      studentIDStr: '',
      students: [],

      studentData: {
        full_name: '',
        className: '',
        id: '',
        sessionId: '',
        campusName: '',
        sessionName: '',
        classId: '',
        sectionName: '',
        branchId: '',
      },
      /*
      * propertiess for student search
      */
      idState: null,
      is_show: false,
      idExceed: false,

      fromDateTime: '',
      toDateTime: '',
      isShow: false,
      isLoading: false,

      documentData: {},
      studentInfo: {},
      isPrintDocument: false,
      dateRange: {},
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
  },
  methods: {
    /*
     * Auto sugession code here for student id search
    */
    getSuggestionValueForStudent(suggestion) {
      return suggestion.item.student_id
    },
    onSelectedStudent(option) {
      this.clearFunc()

      this.studentID = option.item.student_id
      this.studentIDStr = option.item.student_id.toString()
      this.studentName = `${option.item.student_id}`
      // this.studentName = `${option.item.student_id}`
      this.idState = null
      this.studentData.name = option.item.full_name
      this.studentData.id = option.item.student_id
      this.studentData.classId = option.item.std_academic.class_id
      this.studentData.className = option.item.std_academic.student_class.name
      this.studentData.campusName = option.item.std_academic.branch.name
      this.studentData.branchId = option.item.std_academic.branch_id
      this.studentData.sessionId = option.item.std_academic.secondary_session.id
      this.studentData.sessionName = option.item.std_academic.secondary_session.name
      this.studentInfo = option.item
      if (this.studentData.id) {
        this.searchFunc()
      }
    //   this.getRemainingAdvance(this.studentData.id)
    },
    onInputChangeStudent(search) {
      this.clearFunc()
      this.studentIDStr = search
      if (!search) {
        this.searchStudents = []
        this.stdFeeConfigSessionOptions = []
        this.is_show = true
        return
      }

      if (search && search.length > 10) {
        this.idExceed = true
        this.searchStudents = []
        this.stdFeeConfigSessionOptions = []

        this.is_show = false
        return
      }

      if (search.length && (search.length >= 4 && search.length <= 10)) {
        this.idExceed = false
        this.$http.get(`${window.apiUrl}accounting/acc-std-charge-fee/students/${search}`)
          .then(res => {
            if (res.status) {
              if (res.data.status) {
                if (res.data.data < 1) {
                  this.is_show = false
                } else {
                  this.searchStudents = [{
                    data: res.data.data,
                  }]
                }
              } else {
                this.searchStudents = []
                this.clearFunc()
              }
            }
          })
          .catch(err => {
            FemsToastrService.error(err?.message)
            this.clearFunc()
          })
      } else {
        this.searchStudents = []
        this.stdFeeConfigSessionOptions = []
        // this.clearFunc()
        this.is_show = false
      }
    },
    /*
     * Auto sugession code here for student id search
    */

    /*
   * validation for student id
  */
    idvalidate() {
      this.idState = null
      if (!this.studentID || !this.studentName) {
        this.idState = false
        return false
      }
      return true
    },
    /*
   * validation for student id and applicant id
  */

    searchFunc() {
      if (!this.idvalidate()) {
        return
      }
      if (!this.studentIDStr) {
        this.clearFunc()
        return
      }
      if (this.idExceed) {
        this.is_show = false
        this.clearFunc()
        return
      }
      this.is_show = true
      this.idExceed = false
      this.getGridData()
    },

    getGridData() {
      this.isLoading = true
      this.rows = []
      this.isShow = true

      const from = this.fromDateTime === '' ? this.fromDateTime : showOnlyDateDBFormat(this.fromDateTime)
      const to = this.toDateTime === '' ? this.toDateTime : showOnlyDateDBFormat(this.toDateTime)

      if (Date(to) < Date(from)) {
        FemsToastrService.error('From Date cannot be greater than To Date')
        return
      }

      const params = {
        fromDate: from,
        toDate: to,
      }

      this.$http
        .put(`${window.apiUrl}accounting/ledger-report/${this.studentID}`, params)
        .then(res => {
          if (res.data.status) {
            this.isShow = true
            this.isLoading = false
            this.rows = res.data.data
            this.documentData = res.data
            this.dateRange = {
              fromDate: this.fromDateTime === '' ? this.fromDateTime : showOnlyDateDBFormat(this.fromDateTime),
              toDate: this.toDateTime === '' ? this.toDateTime : showOnlyDateDBFormat(this.toDateTime),
            }
            if (!this.rows.length) {
              this.isShow = false
              this.isLoading = false
            }
          } else {
            this.rows = []
            this.isShow = true
            this.isLoading = false
          }
        })
        .catch(err => {
          this.rows = []
          this.isShow = true
          this.isLoading = false
          FemsToastrService.error(err?.message)
        })
    },

    clearFunc() {
      this.is_show = false
      this.idExceed = false
      this.isShow = false
      this.studentID = ''
      this.studentIDStr = ''
      this.studentName = null

      this.searchStudents = []
      this.idState = null

      this.pageLength = 10
      this.dir = false
      this.rows = []
      this.tempRows = []

      this.idState = null
      this.is_show = false
      this.idExceed = false

      this.fromDateTime = ''
      this.toDateTime = ''
      this.isShow = false
      this.isLoading = false

      this.documentData = {}
      this.studentInfo = {}
      this.isPrintDocument = false
      this.dateRange = {}

      this.studentID = ''
      this.searchStudents = []
      this.studentName = ''
      this.studentIDStr = ''
      this.students = []

      this.studentData = {
        full_name: '',
        className: '',
        id: '',
        sessionId: '',
        campusName: '',
        sessionName: '',
        classId: '',
        sectionName: '',
        branchId: '',
      }
    },

    printFunc() {
      if (!this.rows.length) {
        FemsToastrService.error('No Ledger data to be print')
        return
      }
      this.isPrintDocument = true
      this.$nextTick(() => {
        this.$refs.docRef.printDocument()
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.left-align{
  padding-left: 10px;
}
.right-align{
  text-align: right;
  padding-right: 20px
}
.table-hover tbody tr {
  cursor : text !important;
}
</style>
